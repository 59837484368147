<template>
  <a-tree :tree-data="taskProcessData" defaultExpandAll>
    <template #title="{ key: stepKey, title, isLeaf,index}">
      <span v-if="!isLeaf" id="ms-step-title">{{ title }}</span>

      <a-popover
        v-else-if="
          (isLeaf && isNewStep(index)) ||
            (!getMenuItems(stepKey).length && !hasMergeSteps(stepKey))
        "
        title=""
        :trigger="['contextmenu']"
      >
        <template #content>
          <a-typography-text v-if="isNewStep(index)" id="ms-new-step-text">
            <info-circle-outlined class="text-info mr-1" /> It's a new steps,
            You don't need to merge it.
          </a-typography-text>

          <a-typography-text v-else id="ms-no-step-text">
            <info-circle-outlined class="text-info mr-1" /> There is no existing
            step(s) you can merge into.
          </a-typography-text>
        </template>
        <label
          :class="{
            'text-primary': isNewStep(index),
          }"
          :id="title + '-substep-label'"
          style="width:100%"
          >{{ title }}</label
        >
      </a-popover>

      <div
        v-else-if="isLeaf && hasMergeSteps(stepKey)"
        id="ms-parent-step-title"
      >
        {{ title }}
        <a-tag
          v-for="mergedStep in getMergeSteps(stepKey)"
          closable
          :key="mergedStep"
          color="blue"
          @close="() => removeMergeStep(mergedStep, stepKey)"
          :id="title + '-child-tag'"
        >
          {{ mergedStep }}
        </a-tag>
      </div>

      <a-dropdown :trigger="['contextmenu']" v-else>
        <span :id="title + '-ms-options-title'">{{ title }}</span>
        <template #overlay>
          <a-menu>
            <a-menu-item v-if="getMenuItems(stepKey).length"
              ><strong>Merge into:</strong></a-menu-item
            >
            <a-menu-item
              v-for="{ title, key } in getMenuItems(stepKey)"
              :key="key"
              :id="title + '-merge-option'"
              @click="onStepContextClick(stepKey, key)"
              >{{ title }}</a-menu-item
            >
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-tree>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { StopOutlined, InfoCircleOutlined } from '@ant-design/icons-vue';
export default {
  props: ['processSteps', 'processIndex'],
  components: { StopOutlined, InfoCircleOutlined },
  data() {
    return {
      taskProcessData: [],
    };
  },
  created() {
    let prevStepsCount = 0;
    if (this.processIndex - 1 >= 0) {
      [...this.taskProcesses]
        .slice(0, this.processIndex)
        ?.forEach((p) => (prevStepsCount += p.steps.length));
    }
    this.taskProcessData = this.processSteps.map((step, stepIdx) => ({
      title: step.name,
      key: prevStepsCount + stepIdx,
      children: step.substeps.map(this.getChildStepNodeData),
    }));
  },
  computed: {
    ...mapGetters([
      'mergeStepsDict',
      'stepsToIndexMapping',
      'subStepToStepMapping',
      'taskProcesses',
    ]),
  },
  methods: {
    ...mapActions(['setMergeStepsDict']),

    isNewStep(substepIndex) {
      if (substepIndex === undefined) return true;
      return false;
    },

    hasMergeSteps(stepKey) {
      if (!Object.values(this.mergeStepsDict).length) return false;
      if (this.mergeStepsDict[stepKey]?.length) {
        return true;
      }
    },

    getMergeStepsAttachedWithSteps(stepIndex) {
      const mergeStepEntry = Object.entries(this.mergeStepsDict)?.find(
        ([key, steps]) => {
          const [_, stIndex] = key.split('=');
          return Number(stepIndex) === Number(stIndex);
        }
      );
      return mergeStepEntry?.length ? mergeStepEntry[1] : [];
    },

    getChildStepNodeData(subStep) {
      return {
        title: subStep,
        key: `${subStep}=${this.subStepToStepMapping[subStep]}`,
        index: this.stepsToIndexMapping[subStep],
        isLeaf: true,
      };
    },

    getMergeSteps(stepKey) {
      return this.mergeStepsDict[stepKey];
    },

    getMenuItems(key) {
      const [subStep, stepIndex] = key.split('=');
      const existingMergeSteps = this.getMergeStepsAttachedWithSteps(stepIndex);
      return [...this.taskProcessData]
        .find((step) => step.key === Number(stepIndex))
        .children.filter(({ title, index }) => {
          if (existingMergeSteps && existingMergeSteps.length) {
            return (
              !existingMergeSteps.some((step) => step === title) &&
              title !== subStep &&
              index !== undefined
            );
          }
          return title !== subStep && index !== undefined;
        });
    },

    onStepContextClick(stepKey, menuItemKey) {
      if (!menuItemKey) return;
      const [substep, taskStepIndex] = stepKey.split('=');

      let temp = { ...this.mergeStepsDict };
      temp[menuItemKey] = temp[menuItemKey]
        ? [...temp[menuItemKey], substep]
        : [substep];
      this.setMergeStepsDict(temp);

      const newList = this.taskProcessData.map((node) => {
        if (node.key === Number(taskStepIndex)) {
          return {
            ...node,
            children: node.children.filter((ss) => ss.title !== substep),
          };
        } else return node;
      });
      this.taskProcessData = newList;
    },

    removeMergeStep(childStepName, stepKey) {
      const [_, stIndex] = stepKey.split('=');

      let temp = { ...this.mergeStepsDict };
      temp[stepKey] = temp[stepKey].filter((step) => childStepName !== step);
      !temp[stepKey].length && delete temp[stepKey];
      this.setMergeStepsDict(temp);

      const newList = this.taskProcessData.map((node) => {
        if (node.key === Number(stIndex)) {
          return {
            ...node,
            children: [
              ...node.children,
              this.getChildStepNodeData(childStepName, stIndex),
            ].sort(
              ({ index: s1 }, { index: s2 }) =>
                (s1 === undefined) - (s2 === undefined) || s1 - s2
            ),
          };
        } else return node;
      });
      this.taskProcessData = newList;
    },
  },
};
</script>
